
import './App.css';
import { useEffect, useState } from 'react';
import Unity, { UnityContext } from "react-unity-webgl";
import {
  getCurrentWalletConnected, getBalanceMewa,
  getBalanceSOG, mintNFTs, tokenTransfer, claimToken, burnHeroes,
  signIn,
  SetCurtAccount, GetCurtAccount, CheckLogged
} from "./utils/interact";

const isLogged = false;
const unityContext = new UnityContext({
  loaderUrl: "unity/Build/Build.loader.js",
  dataUrl: "unity/Build/Build.data.unityweb",
  frameworkUrl: "unity/Build/Build.framework.js.unityweb",
  codeUrl: "unity/Build/Build.wasm.unityweb",
  streamingAssetsUrl: "unity/StreamingAssets",
});

// const unityContext = new UnityContext({
//   loaderUrl: "Metarrior/Build/Metarrior.loader.js",
//   dataUrl: "Metarrior/Build/Metarrior.data.unityweb",
//   frameworkUrl: "Metarrior/Build/Metarrior.framework.js.unityweb",
//   codeUrl: "Metarrior/Build/Metarrior.wasm.unityweb",
//   streamingAssetsUrl: "Metarrior/StreamingAssets",
// });

export const SendAddressToGame = (_address) => {
  //console.log(_address);
 // unityContext.send("WebGLListener", "ListenerAddress", _address);
};

function App() {
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [progression, setProgression] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  function handleOnClickFullscreen() {
    unityContext.setFullscreen(true);
  }

  function addWalletListener() {
   
    // if (window.ethereum) {
    //   window.ethereum.on("accountsChanged", (accounts) => {
    //     console.log("----- on Here ! -----");
    //     let currentAccount = GetCurtAccount();
    //     if (accounts.length > 0) {
    //       //  isLogged = true;
    //       if (accounts[0] !== currentAccount) {
    //         currentAccount = accounts[0];
    //         SetCurtAccount(accounts[0]);
    //       }
    //       setWallet(accounts[0]);

    //       DisconnectGame();
    //       //window.location.reload(false);
    //     } else {
    //       // isLogged = false;
    //       currentAccount = null;
    //       setWallet("");
    //       SetCurtAccount(null);
    //     }
    //   });
    //   //let currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
    //   window.ethereum.on('chainChanged', (chainId) => {
    //     const targetChainId = process.env.REACT_APP_TARGET_CHAIN_ID;
    //     // Handle the new chain.
    //     // Correctly handling chain changes can be complicated.
    //     // We recommend reloading the page unless you have good reason not to.
    //     // if (chainId != targetChainId) {
    //     //   alert("Please switch to BSC Testnet");
    //     // }
    //     // DisconnectGame();
    //     //window.location.reload();
    //   });

    // } else {
    //   alert("You must install Metamask, a virtual Ethereum wallet, in your browser.");
    //   window.open("https://metamask.io/download");
    // }
  }

  const connectWalletPressed = async () => { //TODO: implement
    if (window.ethereum) {
      //signIn();
    }
  };

  //#################### Function Call to Unity #########################

  function DisconnectGame() {
    unityContext.send("WebGLListener", "DisconnectGame");
  }


  function EventMintNFts(_data) {
    unityContext.send("CanvasSummon", "EventMintNFts", _data);
  }

  function EventRejectNFT() {
    unityContext.send("CanvasSummon", "RejectMintNFT");
  }

  function EventClaimToken(_txHash) {
    unityContext.send("WebGLListener", "ListenerClaim", _txHash);
  }

  function EventTransfer(_data) {
    unityContext.send("WebGLListener", "ListenerTransfer", _data);
  }

  function LogUtil(_message) {
    unityContext.send("WebGLListener", "ErrorMessage", _message);
  }

  function EventRejectAscension() {
    unityContext.send("WebGLListener", "RejectAscension");
  }

  function EventAscension(_txHash) {
    unityContext.send("WebGLListener", "EventAscension", _txHash);
  }

  function EventCheckLogged(_status) {
    unityContext.send("WebGLListener", "EventCheckLogged", _status);
  }

  function EventQueryDebugLog(isLog) {
    console.log("**** isLog: " + isLog);
    if (isLog == "1") {
     // unityContext.send("WebGLListener", "EventQueryDebugLog", "1");
    }
  }
  function EventQueryIsDev(isDev) {
    console.log("**** isDev: " + isDev);
    if (isDev == "1") {
     // unityContext.send("WebGLListener", "EventQueryIsDev", "1");
    }
  }
  function EventRejectClaim(){
    unityContext.send("WebGLListener", "EventRejectClaim");
  }
  //################## Event listeners from Unity #################


  useEffect(async () => { //TODO: implement



    // const { address, status } = await getCurrentWalletConnected();
    // setWallet(address);
    // setStatus(status);
    // addWalletListener();

    // unityContext.on("ConnectMetamask", function () {
    //   //connectWalletPressed();
    // });

    // // summon 10 hero
    // unityContext.on("MintNfts", function (_lstHeroID) {
    //   onMintNfts(_lstHeroID);
    // });

    // unityContext.on("Claim", function (_data) {
    //   onClaimToken(_data);
    // });

    // unityContext.on("TransferToken", function (_data) {
    //   const jsonDt = JSON.parse(_data);
    //   const value = jsonDt[0];
    //   const tkName = jsonDt[1];
    //   onTransferToken(value, tkName);
    // });

    // unityContext.on("AscensionHero", function (_lstTokenId) {
    //   onAscensionHero(_lstTokenId);
    // });

    unityContext.on("progress", function (progression) {
      setProgression(progression);
    });
    unityContext.on("loaded", function () {
      setIsLoaded(true);
      // const queryParams = new URLSearchParams(window.location.search);
      // const dv = queryParams.get("dv");
      // const dl = queryParams.get("dl");
      // EventQueryDebugLog(dl);
      // EventQueryIsDev(dv);
    });

    // unityContext.on("CheckConnectMetamask", function () {
    //   onCheckConnected();
    // });

  }, []);
  //################## ################# #################


  const onMintNfts = async (m_listHeroId) => {
    if (m_listHeroId != "") {
      const { transactionHash, tokens, success } = await mintNFTs(walletAddress, m_listHeroId);
      if (success) {
        const dt = new Object();
        dt.txHash = transactionHash;
        dt.lsToken = tokens;
        const data = JSON.stringify(dt);
        console.log(data);
        EventMintNFts(data);
      } else {
        if (transactionHash) {
          if (transactionHash.code == "4001") {
            EventRejectNFT();
          } else {
            EventMintNFts("");
          }
          LogUtil(transactionHash.message);
          //  alert(transactionHash.code + ": " + transactionHash.message);
        }
      }
    } else {
      console.log("List heroes null");
      LogUtil("List heroes null");
    }
  };

  const onClaimToken = async (m_data) => {
    console.log("m_data: " + m_data);
    // m_signature = "0x4f5dcc05aff47cf06fa09c12c78fed2f31046c54ba5683989274119ee1625a4574f904529b936553c862f35adfa7d83cf42130cbe82e01cd38fc52be044881061b";
    if (m_data) {
      const { success, txHash } = await claimToken(m_data);
      console.log(txHash);
      if (success) {
        if (txHash != "") {
          EventClaimToken(txHash);
        }
      } else {
        if (txHash) {
          if (txHash.code == "4001") {
           
          } else {
            
          }
          EventRejectClaim();
          // alert(txHash.code + ": " + txHash.message);
          LogUtil(txHash.message);
        }
      }

    } else {
      console.log("__ Signature not found ___");
      LogUtil("Signature not found");
    }
  };

  const onTransferToken = async (m_value, m_tkName) => {
    // m_value = "1000";
    // m_tkName = "SOG";
    var balanceOf;
    if (m_tkName == "SOG") {
      balanceOf = await getBalanceSOG();
    }
    else if (m_tkName == "MEWA") {
      balanceOf = await getBalanceMewa();
    }
    console.log(balanceOf);
    if (parseInt(balanceOf) >= parseInt(m_value)) {
      const { success, hash } = await tokenTransfer(m_value, m_tkName);
      if (success) {
        if (hash != "") {
          const data = {
            txHash: hash,
            value: m_value,
            nToken: m_tkName
          }
          EventTransfer(JSON.stringify(data));
        }
      } else {
        if (hash) {
          if (hash.code == "4001") {

          } else {

          }
          LogUtil(hash.message);
          // alert(hash.code + ": " + hash.message);
        }
      }
    } else {
      console.log("Not Enough Token");
      LogUtil("Not Enough Token");
    }

  };

  const onAscensionHero = async (_lstTokenId) => {
    const response = await burnHeroes(_lstTokenId);
    if (response.success) {
      //const data = JSON.stringify(dt);
      //console.log(data);
      EventAscension(response.hash);
    } else {
      if (response.hash) {
        if (response.hash.code == "4001") {
          EventRejectAscension();
        } else {
          EventAscension("");
        }
        LogUtil(response.hash.message);
      }
    }
  };

  const onCheckConnected = async () => {
    const response = await CheckLogged();
    if (response.isLogged) {

    } else {

    }
  };


  return (

    <div className="App">
      <div class="webgl-wrapper">
        <div class="aspect"></div>
        <div class="webgl-content">
          <div id="unityContainer">
            {/* {progression < 1 ? (<p>Loading {progression * 100} percent...</p>) : (<br></br>)} */}
            {/* The loading screen will be displayed here. */}
            {isLoaded === false && (
              <div className="loading-overlay">
                <div className="progress-bar">
                  <img src="../unity/TemplateData/loading_1.png" />
                  <div className="progress-bar-fill">
                    <img
                      style={{ width: progression * 100 + "%" }}
                      src="../unity/TemplateData/loading_2.png"
                    />
                  </div>
                </div>
              </div>

            )}
            {/* The Unity app will be rendered here. */}
            <Unity unityContext={unityContext} devicePixelRatio={2} style={{ background: "#231F20" }} />
            {/* <canvas id="unity-canvas" style="background: #231F20"></canvas> */}
          </div>
          <button onClick={handleOnClickFullscreen} className="btnFullcreen" >
            <img src="../unity/TemplateData/fullscreen-button.png" />
          </button>
        </div>
      </div>
      {/* <button id="mintButton" onClick={onBurnHeroes}>
        Test
      </button> */}
    </div>
  );
}

export default App;
