
import Web3 from 'web3';
import contractMEWA from "../contracts/Metarrior.json";
import contractHero from "../contracts/Hero.json";
import contractClaim from "../contracts/ClaimToken.json";
import {SendAddressToGame} from "../App"
//** */
const HERO_ADDRESS = process.env.REACT_APP_HERO_ADDRESS;
const CLAIM_MEWA_ADDRESS = process.env.REACT_APP_CLAIMMEWA_ADDRESS;
const MEWA_ADDRESS = process.env.REACT_APP_MEWA_ADDRESS;
const PUBLISH_ADDRESS = process.env.REACT_APP_PUBLISHER_ADDRESS;
const SOG_ADDRESS = process.env.REACT_APP_SOG_ADDRESS;
const CLAIM_SOG_ADDRESS = process.env.REACT_APP_CLAIMSOG_ADDRESS;
const URL_TESTNET = process.env.REACT_APP_URL_BSC_TEST;
const MAX_TOKEN_CLAIM = process.env.REACT_APP_MAX_TOKEN_CLAIM;

const ERR_0 = process.env.REACT_APP_ERR_0;
const ERR_1 = process.env.REACT_APP_ERR_1;
const ERR_2 = process.env.REACT_APP_ERR_2;

const TARGET_CHAIN_ID = process.env.REACT_APP_TARGET_CHAIN_ID;
const TARGET_CHAIN_NAME = process.env.REACT_APP_TARGET_CHAIN_NAME;
const TARGET_BLOCK_EXPLORER_URL = process.env.REACT_APP_TARGET_BLOCK_EXPLORER_URL;
const GAME_NAME = process.env.REACT_APP_GAME_NAME;
const CHAIN_ID = parseInt(process.env.REACT_APP_CHAIN_ID);//test net
const RPC_URL_97 = "https://data-seed-prebsc-1-s1.binance.org:8545";//main net
var myAddress;
let currentAccount = null;
export const getWeb3 = async () => {
    // Wait for loading completion to avoid race conditions with web3 injection timing.
    if (window.ethereum) {
        const web3 = new Web3(window.ethereum);
        try {
            // Request account access if needed
           // await window.ethereum.enable();
            await await window.ethereum.request({method: `eth_requestAccounts`})
            // Acccounts now exposed
            return web3;
        } catch (error) {
            console.error(error);
        }
    }
    // Legacy dapp browsers...
    else if (window.web3) {
        // Use Mist/MetaMask's provider.
        const web3 = window.web3;
        console.log('Injected web3 detected.', window.web3);
        return web3;
    }
    // Fallback to localhost; use dev console port by default...
    else {
        const provider = new Web3.providers.HttpProvider(RPC_URL_97);
        const web3 = new Web3(provider);
        console.log('No web3 instance injected, using Local web3.');
        return web3;
    }
};



export const _intializeContract = async (contractABI, addressContract) => {
    const web3js = await getWeb3();
    // We first initialize ethers by creating a provider using window.ethereum
    //   // When, we initialize the contract using that provider and the token's
    //   // artifact. You can do this same thing with your contracts.
    const contract = await new web3js.eth.Contract(contractABI, addressContract);
    return contract;
};


export const SetCurtAccount = async (_address) => {
    currentAccount = _address;
};
export const GetCurtAccount = async () => {
    return currentAccount;
};

export const connectWallet = async () => {
    if (window.ethereum) {
        try {
            const accounts = await window.ethereum.request({
                method: "eth_requestAccounts",
            });

            if (accounts.length === 0) {
                // MetaMask is locked or the user has not connected any accounts
                console.log('Please connect to MetaMask.');
                currentAccount = null;
                return {
                    address: "",
                    status: "Please connect to MetaMask."
                };
            } else if (accounts[0] !== currentAccount) {
                currentAccount = accounts[0];
                // Do any other work!
                console.warn("connectWallet:" + currentAccount);
                const obj = {
                    status: "",
                    address: accounts[0]
                };
                return obj;
            } else if (accounts[0] === currentAccount) {
                return {
                    address: currentAccount,
                    status: ""
                };
            }

        } catch (err) {
            return {
                address: "",
                status: err.message,
            };
        }
    } else {
        return {
            address: "",
            status: "err_0"
        };
    }
};


export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_accounts",
            });
            if (addressArray.length > 0) {
                currentAccount = addressArray[0];
                console.log("====== " + currentAccount);
                return {
                    address: addressArray[0],
                    status: "",
                };
            } else {
                return {
                    address: "",
                    status: "err_1",
                };
            }
        } catch (err) {
            return {
                address: "",
                status: err.message,
            };
        }
    } else {
        return {
            address: "",
            status: "err_0"
        };
    }
};

export const switchToBSCTestnet = async () => {
    if (window.ethereum) {
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x61' }],
            });
        } catch (switchError) {
            // This error code indicates that the chain has not been added to MetaMask.
            if (switchError.code === 4902) {
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: TARGET_CHAIN_ID,
                                chainName: TARGET_CHAIN_NAME,
                                rpcUrls: [TARGET_BLOCK_EXPLORER_URL] /* ... */,
                                nativeCurrency: {
                                    name: 'BNB',
                                    symbol: 'BNB',
                                    decimals: 18
                                }
                            },
                        ],
                    });
                    return {
                        status: ""
                    };
                } catch (addError) {
                    // handle "add" error
                    return {
                        status: addError
                    };
                }
            }
            // handle other "switch" errors
            return {
                status: switchError
            };
        }
    } else {
        return {
            status: "err_0"
        };
    }
};
export const switchToBSCMainnet = async () => {
    if (window.ethereum) {
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x61' }],
            });
        } catch (switchError) {
            // This error code indicates that the chain has not been added to MetaMask.
            if (switchError.code === 4902) {
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: TARGET_CHAIN_ID,
                                chainName: TARGET_CHAIN_NAME,
                                rpcUrls: [TARGET_BLOCK_EXPLORER_URL] /* ... */,
                                nativeCurrency: {
                                    name: 'BNB',
                                    symbol: 'BNB',
                                    decimals: 18
                                }
                            },
                        ],
                    });
                    return {
                        status: ""
                    };
                } catch (addError) {
                    // handle "add" error
                    return {
                        status: addError
                    };
                }
            }
            // handle other "switch" errors
            return {
                status: switchError
            };
        }
    } else {
        return {
            status: "err_0"
        };
    }
};

// export const getTokenData = async () => {
//     var mycontract = await new web3.eth.Contract(TokenArtifact.abi, tokenAddress);
//     console.log(mycontract);
//     const name = await mycontract.methods.name().call();
//     const symbol = await mycontract.methods.symbol().call();
//     const tokenData = { name, symbol }
//     return { tokenData: tokenData };
// };

// export const sendMDToken = async (toAddress, amount) => {
//     if (window.ethereum) {
//         if (toAddress == "" || amount == "") {
//             return {
//                 success: false,
//                 status: "❗Please make sure all fields are completed before minting.",
//             }
//         }
//         console.log(toAddress, amount);
//         await window.ethereum.request({ method: 'eth_requestAccounts' });

//         const mycontract = await new web3.eth.Contract(TokenArtifact.abi, tokenAddress);

//         const account = await web3.eth.getAccounts();
//         console.log(account[0]);
//         try {
//             await mycontract.methods.transfer(toAddress, amount).send({from: "0xb236897F8A8C5a6A314EDb6466F5FdF2248a2daC"})
//         } catch (err) {
//             console.log(err.message)
//         }

//         console.log(`${amount} MDToken has been sent to ${toAddress}`);
//         return {
//             success: true,
//             status: "Transfer success!"
//         }
//     } else {
//         return {
//             success: false,
//             status: (
//                 <span>
//                     <p>
//                         {" "}
//                         🦊{" "}
//                         <a target="_blank" href={`https://metamask.io/download.html`}>
//                             You must install Metamask, a virtual Ethereum wallet, in your
//                             browser.
//                         </a>
//                     </p>
//                 </span>
//             )
//         }
//     }
// };


export const getBalanceMewa = async () => {
    const web3js = await getWeb3();
    const myContract = await _intializeContract(contractMEWA.abi, MEWA_ADDRESS);
    const accounts = await web3js.eth.getAccounts();
    console.log(accounts);
    const balance = await myContract.methods.balanceOf(accounts[0]).call();
    const blc = web3js.utils.fromWei(balance);
    return blc;
};

export const getBalanceSOG = async () => {
    const web3js = await getWeb3();
    const myContract = await _intializeContract(contractMEWA.abi, SOG_ADDRESS);
    const accounts = await web3js.eth.getAccounts();
    console.log(accounts);
    const balance = await myContract.methods.balanceOf(accounts[0]).call();
    // console.log(balance);
    const blc = web3js.utils.fromWei(balance);
    //  console.log("blc: " + blc);
    return blc;

};

export const mintNFT = async (walletAddress, _heroID) => {
    try {
        // const web3js = await getWeb3();
        // const myContract = await new web3js.eth.Contract(contractNFT.abi, contractAddress);//await _intializeContract(contractNFT.abi, contractAddress);
        // const account = await web3js.eth.getAccounts();
        // if (walletAddress == "") {
        //     walletAddress = currentAccount;
        // }
        // // console.log(walletAddress);
        // //console.log(account[0]);
        // let txHash = "";
        // let tokenID = "";
        // await myContract.methods.mintNft(_heroID).send({ from: walletAddress }).on('receipt', function (receipt) {
        //     console.log(receipt);
        //     txHash = receipt.transactionHash;
        // });
        // console.log("txHash: " + txHash);
        // await web3js.eth.getTransactionReceipt(txHash, function (err, rec) {
        //     if (rec) {
        //         console.log(rec);
        //         let logs = rec.logs;
        //         console.log(logs);
        //         console.log(web3js.utils.hexToNumber(logs[0].topics[3]));
        //         tokenID = web3js.utils.hexToNumber(logs[0].topics[3]);

        //     }
        // });
        // console.log("tokenID: " + tokenID);
        // return {
        //     success: true,
        //     status: txHash + "|" + tokenID
        // }
    } catch (error) {
        return {
            success: false,
            status: error.code
        }

    }
};

export const mintNFTs = async (walletAddress, _lstHeroID) => {
    try {
        const web3js = await getWeb3();
        const myContract = await new web3js.eth.Contract(contractHero.abi, HERO_ADDRESS);
        const account = await web3js.eth.getAccounts();
        if (walletAddress == "") {
            walletAddress = currentAccount;
        }
        //console.log(walletAddress);
        //  console.log(account[0]);
        let txHash = "";
        let tokenID = "";
        const _heroIDs = JSON.parse(_lstHeroID);//["Ford2", "BMW2", "Fiat2"];
        //const _heroIDs = ["Ford2", "BMW2", "Fiat2"];
        console.log(_heroIDs);
        const _tokenIDs = [];
        await myContract.methods.mintNfts(_heroIDs).send({ from: walletAddress }).on('receipt', function (receipt) {
            console.log(receipt);
            txHash = receipt.transactionHash;
        });
        console.log("txHash: " + txHash);
        await web3js.eth.getTransactionReceipt(txHash, function (err, rec) {
            if (rec) {
                console.log(rec);
                let logs = rec.logs;
                console.log(logs);
                if (logs.length > 0) {
                    for (let i = 0; i < logs.length; i++) {
                        const tk = web3js.utils.hexToNumber(logs[i].topics[3]);
                        _tokenIDs.push(tk);
                    }
                }
                // console.log(web3js.utils.hexToNumber(logs[0].topics[3]));
                //tokenID = web3js.utils.hexToNumber(logs[0].topics[3]);

            }
        });
        // console.log("_tokenID: " + _tokenIDs);
        return {
            success: true,
            tokens: _tokenIDs,
            transactionHash: txHash
        }
    } catch (error) {
        return {
            success: false,
            tokens: [],
            transactionHash: error
        }

    }
};


export const waitForBlock = async (block) => {
    const web3js = await getWeb3();
    while (true) {
        const currentBlock = await web3js.eth.getBlockNumber();
        console.log(currentBlock);
        if (currentBlock >= block) {
            return;
        }
        await sleep(2000);
    }
    // const myContract = await new web3js.eth.Contract(contractHero.abi, HERO_ADDRESS);
    // const heroInfo = await myContract.methods.getHeroByTokenId("19").call();
    // console.log(heroInfo);

    //const balance = await contract.methods.balanceOf(account[0]).call();
    //const balance = await contract.methods.balanceOf("0xf39fd6e51aad88f6f4ce6ab8827279cfffb92266").call();
    //console.log(balance);


    // const wei = web3js.utils.toWei("10");
    //  const tkContract = await new web3js.eth.Contract(contractMEWA.abi, mewaContract);
    //  await tkContract.methods.transfer("0x7fd4a00c973F1730e447A5Bd7E58471F5bd170dF", wei).send({from: accounts[0]});

    // await tkContract.methods.claimTokens("0x7fd4a00c973F1730e447A5Bd7E58471F5bd170dF", wei).send({ from: accounts[0] });
    // var txHash = "0xc1130666b6b8f8987d3fe91bc12736883e98ed165f710ef140e23c7109d58e6e";
    // const receipt = await web3js.eth.getTransactionReceipt(txHash);
    // console.log(receipt);

    // let logs = receipt.logs;
    // console.log(logs);
    // console.log(web3js.utils.hexToNumber(logs[0].topics[3]));
    // let tokenID = web3js.utils.hexToNumber(logs[0].topics[3]);
    // console.log("tokenID: " + tokenID);
    // const privateKey = "0x9421684d165770c12d92b991e6218c153d39e33aae5c631cac42f01078232502";
    // const data = {
    //     to: "0x5a56fE3DF9401B4EFcA1B82c61f98908186b0D3D", // account 9
    //     value: web3js.utils.toWei("10")
    //   };

    // const signdata = await web3js.eth.accounts.sign(data, '0x9421684d165770c12d92b991e6218c153d39e33aae5c631cac42f01078232502');
    // console.log(signdata);
    // const myContract = await new web3js.eth.Contract(contractClaim.abi, claimContract);
    // const claimData = await myContract.methods.claim(data, signdata.signature).send({ from: accounts[0] });
    // console.log(claimData);
};

export const sleep = async (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

export const sign = async (message, address) => {
    const web3js = await getWeb3();
    const shaMess = await web3js.utils.sha3(message);
    const signature = await web3js.eth.personal.sign(web3js.utils.utf8ToHex(shaMess), address);
    return {
        signature
    };
};

export const claimToken = async (_data) => {
    try {
        const web3js = await getWeb3();

        const jsonDt = JSON.parse(_data);
        const m_signature = jsonDt[0];
        const token = jsonDt[1];
        var myContract;
        if (token === "SOG") {
            myContract = await new web3js.eth.Contract(contractClaim.abi, CLAIM_SOG_ADDRESS);
        } else if (token === "MEWA") {
            myContract = await new web3js.eth.Contract(contractClaim.abi, CLAIM_MEWA_ADDRESS);
        }
        const accounts = await web3js.eth.getAccounts();
        console.log("accounts: " + accounts[0]);
        const data = {
            to: accounts[0], // account 9
            value: web3js.utils.toWei("50")
        };
        let txHash = "";

        await myContract.methods.claim(data, m_signature).send({ from: accounts[0] }).on('receipt', function (receipt) {
            console.log(receipt);
            txHash = receipt.transactionHash;
            console.log(txHash);
        });

        return {
            success: true,
            txHash: txHash
        };
    } catch (error) {
        return {
            success: false,
            txHash: error
        }

    }
};

export const tokenTransfer = async (_value, _tkName) => {

    try {
        const web3js = await getWeb3();
        let txHash = "";
        if (_value != "") {
            const value = web3js.utils.toWei(_value);
            var myContract;
            if (_tkName === "SOG") {
                myContract = await new web3js.eth.Contract(contractMEWA.abi, SOG_ADDRESS);
            } else if (_tkName === "MEWA") {
                myContract = await new web3js.eth.Contract(contractMEWA.abi, MEWA_ADDRESS);
            }
            const accounts = await web3js.eth.getAccounts();
            // const tkContract = await new web3js.eth.Contract(contractMEWA.abi, MEWA_ADDRESS);

            await myContract.methods.transfer("0x79addB6d8cabCb61568F5BED4A5457F74300B07d", value).send({ from: accounts[0] }).on('receipt', function (receipt) {
                console.log(receipt);
                txHash = receipt.transactionHash;
                console.log(txHash);
            });
        }

        return {
            success: true,
            hash: txHash,
        };
    } catch (error) {
        return {
            success: false,
            hash: error
        }

    }
};

export const burnHeroes = async (_listTokenId) => {
    try {
        const web3js = await getWeb3();
        const myContract = await new web3js.eth.Contract(contractHero.abi, HERO_ADDRESS);
        const accounts = await web3js.eth.getAccounts();
        const _tokenIDs = JSON.parse(_listTokenId);
       // const _heroIDs = [254, 255];
        let txHash = "";
        await myContract.methods.burnBatchHero(_tokenIDs).send({ from: accounts[0] }).on('receipt', function (receipt) {
            console.log(receipt);
            txHash = receipt.transactionHash;
            console.log(txHash);
        });
        console.log("txHash: " + txHash);

        return {
            success: true,
            hash: txHash,
        };
    } catch (error) {
        return {
            success: false,
            hash: error
        }

    }
};

/**********************************************************/
/* 					Hard Ware SignIn					  */
/**********************************************************/
export const signIn = async () => {
    let dt;
    // logFBEvent("sign_in_click");

    if (currentAccount == null) {
        // alert("Please connect to Metamask first!");
        return connectWallet();
    }
    let currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
    if (currentChainId != TARGET_CHAIN_ID) {
        // alert("Please switch to BSC Testnet!");
        if (TARGET_CHAIN_ID == "0x38")
            return switchToBSCMainnet();
        else
            return switchToBSCTestnet();
    }

    const domain = [
        { name: "game", type: "string" },
        { name: "time", type: "int64" }
    ];

    var time = new Date().getTime();

    const data = [
        { name: "message", type: "string" },
    ];
    const msgParams = JSON.stringify({
        domain: {
            game: GAME_NAME,
            time: time
        },
        primaryType: 'Data',
        message: {
            message: "Connect wallet to " + GAME_NAME,
        },
        types: {
            EIP712Domain: domain,
            Data: data
        }
    });
   
    var from = currentAccount;

    var params = [from, msgParams]
    var method = 'eth_signTypedData_v4'

   await window.ethereum.sendAsync({
        method,
        params,
        from,
    }, function (err, result) {
        if (err) {
            let err_code = err.code;
             console.log(err);
             return;
        }
        if (result.error) {

            alert(result.error.message);
            
        }
        if (result.error) {
            console.error('ERROR', result);
            return;
        } 

        var signData = result.result;

        //console.log('msgParams:' + msgParams)
        console.log('TYPED SIGNED:' + signData)

        dt = {
            Address: currentAccount,
            Sign: signData,
            Time: time,
        }

        //send to unity
        console.log(dt);
        SendAddressToGame(dt.Address);
    });
    
};

export const CheckLogged = async () =>{
    const web3js = await getWeb3();
    const accounts = await web3js.eth.getAccounts();
    if(accounts.length > 0){
        return {
            isLogged: true,
        }
    }else{
        return {
            isLogged: false,
        }
    }
};
